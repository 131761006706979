import React, { RefObject, useState} from 'react'
import dynamic from 'next/dynamic'
import useScrollPosition from '../../lib/useScrollPostition'

const ScrollToTopTransition = dynamic(()=>import('./ScrollToTopTransition'))

const TOP_TRESHOLD = 400
const BOTTOM_TRESHOLD = 265

const ScrollToTop = ({menuRef} : {menuRef: RefObject<HTMLDivElement>}) => {
    const scrollPosition = useScrollPosition()
    const [showedOnce, setShowedOnce ] = useState(false)
    const isVisible = scrollPosition.top > TOP_TRESHOLD && scrollPosition .bottom > BOTTOM_TRESHOLD
    
    if (isVisible && !showedOnce) {
        setShowedOnce(true)
    }

    return (isVisible || showedOnce)
        ? <ScrollToTopTransition menuRef={menuRef} isVisible={isVisible} />
        : <React.Fragment />
}

export default ScrollToTop